import * as React from "react"
import {graphql} from "gatsby";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import WideColumnSidebar from "../layouts/wide-column-sidebar/wide-column-sidebar";
import Metatags from "../components/metatags/metatags";
import ArticleHeader from "../components/blocks/article-header/article-header";
import BlockSectionDescription from "../components/blocks/block-section-description/block-section-description";
import FooterTaxonomy from "../components/blocks/footer/footer-taxonomy/footer-taxonomy";
import ImageListQueue from "../components/blocks/image-list-queue/image-list-queue";


export const data = graphql `
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    featuredBooks: allEntitySubqueueFeaturedBooks {
      edges {
        node {
          relationships {
            items {
              field_link {
                title
                uri
              }
              relationships {
                field_image {
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(height: 440, width: 270, transformOptions: {cropFocus: CENTER})
                        }
                      }
                    }
                  }
                  field_media_image {
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
    featuredItems: allEntitySubqueueFeaturedBookItems {
      edges {
        node {
          relationships {
            items {
              field_link {
                title
                uri
              }
              relationships {
                field_image {
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(height: 220, width: 320, transformOptions: {cropFocus: CENTER})
                        }
                      }
                    }
                  }
                  field_media_image {
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
    featuredCategories: allEntitySubqueueFeaturedBookCategories {
      edges {
        node {
          relationships {
            items {
              field_link {
                title
                uri
              }
              relationships {
                field_image {
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(height: 220, width: 320, transformOptions: {cropFocus: CENTER})
                        }
                      }
                    }
                  }
                  field_media_image {
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function PodcastPage({data, pageContext}) {

  const { t } = useTranslation();

  const header = <ArticleHeader
    iconType="podcast"
    sectionName={t('books-library.title', 'Books & Library')}
    showNewsletter={false}
    isHeader={true}
    ></ArticleHeader>

  const featuredBooks = <ImageListQueue
    title={t('books-library.featured', 'Featured Books')}
    titleTag="h2"
    items={data.featuredBooks.edges[0].node.relationships.items}
    linkTitle={t('books-library.featured_linkTitle', 'View all books')}
    linkUrl='/books'
    ></ImageListQueue>

  const featuredItems = <ImageListQueue
    title={t('books-library.items', 'Featured Items')}
    titleTag="h2"
    items={data.featuredItems.edges[0].node.relationships.items}
    ></ImageListQueue>

  const featuredCategories = <ImageListQueue
    title={t('books-library.categories', 'Featured Categories')}
    titleTag="h2"
    items={data.featuredCategories.edges[0].node.relationships.items}
    ></ImageListQueue>


  const mainContent = [ header, featuredBooks, featuredItems, featuredCategories];


  const sidebar = 'need menus'

  const bottom = <FooterTaxonomy></FooterTaxonomy>

  return (
      <WideColumnSidebar
        wide={mainContent}
        sidebar={sidebar}
        bottom={bottom} >
      </WideColumnSidebar>
  );
}
